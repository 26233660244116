
import {Vue, Component} from 'vue-property-decorator';
import {ListDataSource} from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";
import SubscriberDialog from "@/pages/manage/events/components/subscriber-dialog.vue";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";

@Component({
  components: {SubscriberDialog}
})
export default class index extends Vue {
  visible = false


  eventObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.eventId,
    config: {},
    className: "event",
  });


  public addUser(){
    this.visible = true
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "События",
        to: "/manage/events/",
        exact: true,
      },
      {
        text: this.eventObjectDataSource.model?.caption,
        to: `/manage/events/${this.$route.params.eventId}`,
        exact: true,
      },
      {
        text: "Подписчики",
        disabled: true,
      },
    ];
  }

  public async add(data:any){
    await this.subscriberDataSource.add({
      eventId: +this.$route.params.eventId,
      userProfileId: data
    })
    await this.dataSource.get()
  }

  async remove(item:any){
    const subscribe = await this.subscriberDataSource.items.find(i => i.userProfileId === item.id);
    if(subscribe == null) return;
    await this.subscriberDataSource.remove(subscribe.id)
    await this.dataSource.get()
  }

  headers: Array<any> = [
    {
      text: "ID",
      value: "id",
      sortable: true,
      width: 100,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: false,
    },
    {
      text: "Email",
      value: "email",
      sortable: false,
    },
    {
      text: "ФИО",
      value: "fullName",
      sortable: false,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  dataSource = new ListDataSource({
    className:"UserProfile",
    config:{
      filter:JSON.stringify([
        new Filter("eventSubscribe", +this.$route.params.eventId),
      ])
    }
  })

  subscriberDataSource = new ListDataSource({
    className:"EventSubscriber",
    config:{
      filter:JSON.stringify([
        new Filter("eventId", +this.$route.params.eventId)
      ])
    }
  })


  async mounted(){
    await this.subscriberDataSource.get();
    await this.eventObjectDataSource.get();
  }

}


import { Vue, Component, Prop } from "vue-property-decorator";
import { SecurityGroupAccessType } from "@/pages/manage/security-group/common/consts";
import { ListDataSource } from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";

@Component
export default class SecurityGroupDialog extends Vue {
  @Prop() value!: boolean;
  @Prop({ default: () => [] }) exludedUserProfileIdx!: number[];
  securityGroupAccessType = SecurityGroupAccessType.UserProfile;
  resolve!: Function;


  userProfileFilterPredicate(up: any) {
    return !this.exludedUserProfileIdx.some((i) => i == up.id);
  }

  headers: Array<any> = [
    {
      text: "ID",
      value: "id",
      sortable: true,
      width: 100,
    },
    {
      text: "Email",
      value: "email",
      sortable: false,
    },
    {
      text: "ФИО",
      value: "fullName",
      sortable: false,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];


  userProfileDataSource: ListDataSource = new ListDataSource({
    className: "userProfile",
    config: {
      pageIndex: 1,
      pageSize: 15,
      filter:JSON.stringify([
        new Filter("roleNames", "Administrators,Site Editors,Event Managers")
      ])
    },
  });


  addByUserProfile(userProfile: any) {
    this.$emit("add", userProfile.id);
  }

}
